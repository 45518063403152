$label-color: #00000099;
$text-color: #000000b3;
$button-text-color:var(--button-text-color,#fff);
$primary: var(--primary-color, #2C3C5D);
$secondary: var(--secondary-color, --primary-color);
$saveProgressButton: var(--save-progress-button-color, #5764A2);
$error-color: #ee0000;
$active-input-color:var(--active-input-color,#00000066) ;
$button-disabled-color:var(--button-disabled-color);
$tooltip-background-color: var(--tooltip-background-color);

.mat-spinner circle {
  stroke: #a0a0a0;
}

/**************     Layout      ****************/
* {
  box-sizing: border-box;
}

a {
  color: $secondary;
}

main {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #fafbfe;
}

app-main-wrapper {
  display: block;
  width: 100%;
}

#activate-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  .mat-mdc-checkbox.mat-accent{
    --mdc-checkbox-selected-checkmark-color:#00000099;
    --mdc-checkbox-selected-icon-color:#FEDB00;
    --mdc-checkbox-selected-hover-icon-color:#FEDB00;
    --mdc-checkbox-selected-hover-icon-color:#FEDB00;
    --mdc-checkbox-selected-pressed-icon-color:#FEDB00;
    --mdc-checkbox-unselected-icon-color: rgba(0,0,0,.30196078431372547);
    --mdc-checkbox-unselected-hover-icon-color: rgba(0,0,0,.30196078431372547);
    --mdc-checkbox-unselected-focus-icon-color: rgba(0,0,0,.30196078431372547);
    --mdc-checkbox-unselected-pressed-icon-color: rgba(0,0,0,.30196078431372547);

  }
  .mdc-checkbox{
    padding: 0px;
    width: 13px;
    height: 13px;
    flex: 0 0 13px;
  }
  .mdc-checkbox__background{
    top: 0px;
    left: 0px;
    width: 13px;
    height: 13px;
  }
  .mat-mdc-checkbox-ripple{
    top: -12px;
    left: -13px;
    height: 40px;
    width: 40px;
  }
  .mat-mdc-checkbox label{
    font-size: 14px;
    font-weight: 400;
    line-height: 21.5px;
    padding-left: 10px ;
  }
}

@media(max-width: 768px) {
  #activate-wrapper{
    &:has(> app-new-coverage-page){  
        min-height: calc(100vh - 75px) !important;
    }
    &:has(> app-page-not-found){
        min-height: calc(100vh - 80px) !important;
      + #custom-footer{
        height: 80px;
      }
    }
  }
}

#progress-wrapper:has(+ router-outlet + app-page-not-found) {
  display: none;
}

#activate-wrapper
  > *:not(#header-wrapper):not(footer):not(router-outlet):not(app-service-plan):not(app-tranponder-id):not(#progress-wrapper):not(app-welcome):not(app-add-spreadsheet):not(app-promo-banner):not(app-payment-summary):not(app-car-make):not(app-vehicles) {
  flex-grow: 1;
  align-self: center;
  width: 100%;
  max-width: 430px;
  padding: 0 15px 0 15px;
}

.main-wrapper-style-class{
  flex-grow: 1;
  align-self: center;
  width: 100%;
  max-width: 430px;
  padding: 0 15px 0 15px;
}

 app-new-coverage-page{
  max-width:700px !important;
  color:#333333;
 }
 
 app-trucker-service-plan,app-shipping-address-speed{
  max-width:890px !important;
  color:#333333;
  padding: 0px !important;
}

app-service-plan,
app-transponder-id {
  width: 100%;
  display: flex;
  justify-content: center;
}

app-welcome{
  background: #fff;
}

#add-bypass-option-header {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#ws-additional-info {
  font-weight: bold;
  font-size: 0.6rem;
  line-height: 2.5;
}

#ws-title {
  font-size: 26px;
  font-weight: 600;
}

p.instructions {
  margin-bottom: 15px;
}

/*************  Global (non-layout)  ***************/
h1,
h2,
h3,
p,
span,
a,
label,
input,
select,
.mat-select,
button,
li,
::after {
  font-family: "ibm-plex-sans", sans-serif;
}

h1,
h2 {
  font-family: 'IBM Plex Sans', sans-serif;
}

@media(min-width:800px){
  h1 {
  letter-spacing: 0.01em;
  }
}

label {
  letter-spacing: 0.03em;
}

p {
  margin: 0;
}

h1 {
  font-size: 26px;
  font-weight: 600;
}

h2 {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.01em;
}

button {
  cursor: pointer;
}

.white-radio-wrapper {
  border-radius: 14px;
  overflow: hidden;
  position: relative;
  width:300px;
  max-width: 100%;
  color: #000000;
  input{
    &:hover,&:checked, &:focus{
      color: #000;
    }
  }
}

.white-radio {
  border: 1px solid #B3BFF5;
  border-radius: 14px;
  transition: 0.1s background-color linear;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.white-radio-wrapper
  input:not(:hover):not(:checked):not(:focus)
  + .white-radio {
  background-color: white !important;
}

.white-radio-wrapper label {
  color: #000000;
  font-weight: normal;
  font-size: 15px;
  text-align: center;
  transition: 0.1s color linear;
  letter-spacing: 0px;
}

.white-radio-wrapper path {
  fill: black;
  transition: 0.1s fill linear;
}

.white-radio-wrapper path.gray {
  fill: #c0c0c0;
}

.white-radio-wrapper input:hover + .white-radio label,
.white-radio-wrapper input:checked + .white-radio label,
.white-radio-wrapper input:focus + .white-radio label {
  color: black;
}

.white-radio-wrapper input:hover + .white-radio path,
.white-radio-wrapper input:checked + .white-radio path,
.white-radio-wrapper input:focus + .white-radio label {
  fill: white;
}

.white-radio-wrapper input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  margin: 0;
}

.directions,
.label-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-button {
  background: inherit;
  border: none;
  outline: none;
  padding: 5px;
}

label {
  font-size: 12px;
  font-weight: 600;
  color: $label-color;
}

.checkbox-label {
  display: flex;
  align-items: center;
  display: block;
  font-weight: 400;
  color: #00000099;
}

input[type='checkbox'] {
  position: relative;
  top: 4px;
  margin-right: 1.5px;
  width: auto;
  zoom: 1.5;
}

input:not([type='checkbox']):not([type='radio']),
.mat-select,  .mat-mdc-select{
  width: 100%;
  border: 1.5px solid #ddd;
  border-radius: 5px;
  background: white;
  font-size: 16px;
  padding: 4.5px 9px;
  outline: none;
  margin-top: 3px;
}

input:not(:focus),
.mat-select:not(:focus), .mat-mdc-select:not(:focus) {
  border-color: #ddd !important;
}

input.ng-invalid.ng-touched:not(form),
.mat-select.ng-invalid.ng-touched:not(form), 
.mat-mdc-select.ng-invalid.ng-touched:not(form) {
  border-color: #ee0000 !important;
}

input::placeholder,
.dropdown-with-select-option .mat-select-value  {
  color: #aaa;
}

input:disabled {
  background: #eee;
  color: #555;
}

mat-select:disabled {
  background: #eee;
}

.mat-select-disabled .mat-select-value {
  color: #555;
}

.mat-mdc-option .mdc-list-item__primary-text{
  font-family: "ibm-plex-sans", sans-serif !important;
}

.centered-element-wrapper,.display-center {
  display: flex;
  justify-content: center;
}
.element-wrapper-left-aligned {
  display: flex;
  justify-content: left;
}

.space-between-wrapper {
  display: flex;
  justify-content: space-between;
}

button.next,button.signup-itm,.login-itm,.edit,.cancel {
  font-family: "trade-gothic-next", sans-serif;
  width: 100%;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  background-color: $primary;
  border: none;
  border-radius: 4px;
  padding: 8px;
  margin-top: 32px;
  color: $button-text-color;
}

button.back {
  font-family: "trade-gothic-next", sans-serif;
  width: 100%;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.045em;
  background-color: white;
  border-width: 1px;
  border-style: solid;
  padding: 11px 0;
  margin-top: 32px;
  color: $text-color;
}

button.next:disabled {
  background-color: $button-disabled-color !important;
  cursor: disabled;
}

.subtle-underline-button {
  background: inherit;
  border: none;
  font-size: 14px;
  color: #bbb;
  text-decoration: underline;
  padding: 0;
}

a:link,
a:visited {
  color: #bbb;
}

.underline {
  text-decoration: underline;
}

.error-message {
  color: $error-color;
  margin: 7px 2px;
  font-size: 16px;
}

// To add Asterisk symbol for each mandatory fields
.required-asterisk::after {
  content: "*";
  color: $error-color;
}

// For override of accordion in payment-summary page
.mat-expansion-panel-content {
  font-family: "ibm-plex-sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

// Media query to prevent paytrace from pushing cvv field out of view
@media (max-width: 471px) {
  app-credit iframe {
    height: 219px !important;
  }
}

#progress-wrapper {
  & .mat-progress-bar {
    & .mat-progress-bar-fill::after {
      background-color: $primary;
    }
  }
}

.service-plan {
  border: 1px solid #E3E3E3;
  box-shadow: #E3E3E3 1px 1px 5px;
  color: #737373;
  box-sizing: border-box;
  .title {
    background-color: #E3E3E3;
  }
}

.service-plan.active {
  border: 1px solid $primary;
  .title {
    background-color: $primary;
  }
}

.mat-button-toggle {
  color: #0000004d;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 36px;
}
.mat-button-toggle-group {
  border-radius: 30px !important;
}
.mat-checkbox-frame {
  border-color: #0000004d;
  border-radius: 50%;
}
.checkbox-error {
  .mat-checkbox-frame {
    border-color: #ee0000;
  }
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #FEDB00;
}

.mat-checkbox-checkmark-path {
  stroke: #00000099 !important;
}

.mat-checkbox.mat-accent {
  .mat-checkbox-inner-container {
     margin: 5px 8px auto 0;
     width: 13px;
     height: 13px;
  }
}
.mat-checkbox .mat-checkbox-layout {
  white-space: normal;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 21.5px;
}

.mat-checkbox-label {
  font-size: 14px;
  font-weight: normal;
}

/* To prevent white space breaking */
.nowrap {
  white-space: nowrap;
}

/* To show icon button inside input field */
.input-button {
  position: absolute;
  margin-top: 4px;
  background: transparent;
  border: none;
  margin-left: -34px;
  padding-top: 4px;
  outline: none;
  color: $primary;
  @media(max-width: 480px) {
    margin-left: -45px;
  }

  .mat-icon {
    font-size: 18px;
  }
}
.input-button:hover + .tooltip-desktop, .tooltip-desktop:hover {
  display: block;
}
.tooltip {
  display: none;
  font-size: 12px;
  color: #00000099;
  background-color: $tooltip-background-color;
  padding: 0.7rem 1rem;
  border-top: 2px solid #fafbfe;
  z-index: 1;
  line-height: 1.6em;
}
.tooltip-mobile {
  display: block;
}
.tooltip-desktop {
  position: absolute;
  width: 73%;
  right: 0;
}
.button-in-tooltip {
  border: none;
  float: right;
  background-color: transparent;
  margin-top: -4px;
  margin-right: -4px;
  padding: 0;
  color: #0000004d;

  .mat-icon {
    color: #00000099;
    font-size: 18px;
  }
}
.mb-15{
  margin-bottom: 15px;
}
.mt-mb-20{
  margin-top:20px!important;
  margin-bottom:20px;
}

.mt-20{
  margin-top:20px;
}
.save-return{
  color: $saveProgressButton;
}


.mdc-menu-surface.mat-mdc-select-panel{
  border-radius: 4px !important;
  padding: 0px !important;
}
.mdc-list-item__primary-text{
  color: #000000de !important;
}
.mat-mdc-option-active{
  background: rgba(0,0,0,.12)!important;
}

@media(max-width: 480px) {
.centered-element-wrapper{
    background-color: #FFF;
    position: fixed;
    left: 0px;
    bottom: 0%;
    width: 100%;
    height: auto;
    border-radius: 0px;
    z-index: 1000;
    padding: 15px;
}
button.next {  
  height: 50px;
  border-radius: 0px;
  z-index: 1000;  
  margin-top: 0px;
}
}

@import url("https://fonts.googleapis.com/icon?family=Material+Icons"); 